var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isMobile)?_c('b-list-group-item',{staticClass:"flex-column align-items-start"},[_c('div',{staticClass:"d-flex w-100 justify-content-between row"},[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('b-row',[_c('b-col',{staticClass:"px-0 m-auto text-center",attrs:{"cols":"3"}},[_c('router-link',{attrs:{"to":{
                                name: 'door',
                                params: {
                                    locationId: _vm.device.Device.LocationId,
                                    doorId: _vm.device.Device.Id,
                                    companyId: _vm.device.Device.CompanyId,
                                },
                            }}},[_c('b-avatar',{staticClass:"mr-2 mw-48",attrs:{"rounded":"lg","size":"2rem","variant":"light","src":_vm.device.Device.SmallImageUrl &&
                            _vm.device.Device.SmallImageUrl !=
                                'https://zesec-prod.s3.eu-central-1.amazonaws.com//_150x150'
                                ? _vm.device.Device.SmallImageUrl
                                : '/images/zesec-placeholder.png'}})],1)],1),_c('b-col',{staticClass:"pl-0 my-auto",attrs:{"cols":"9"}},[_c('h5',{staticClass:"mb-1 text-truncate"},[_c('router-link',{attrs:{"to":{
                                name: 'door',
                                params: {
                                    locationId: _vm.device.Device.LocationId,
                                    doorId: _vm.device.Device.Id,
                                    companyId: _vm.device.Device.CompanyId,
                                },
                            }}},[_vm._v(_vm._s(_vm.device.Device.Name))])],1)])],1)],1),_c('b-col',{attrs:{"lg":"2","md":"2","sm":"4"}},[_c('small',[_vm._v(" "+_vm._s(_vm.$t("common.count_keys", { count: _vm.device.KeysCount, }))+" ")])]),_c('b-col',{attrs:{"lg":"2","md":"2","sm":"4"}},[_c('small',[_vm._v(" "+_vm._s(_vm.$t("common.count_users", { count: _vm.device.UsersCount, }))+" ")])]),_c('b-col',{attrs:{"lg":"2","md":"2","sm":"4"}},[_c('b-badge',{attrs:{"variant":"light"}},[_vm._v(" "+_vm._s(_vm.getDeviceProvider(_vm.device.Device.ProviderType).key)+" ")])],1)],1)]):_c('b-list-group-item',{staticClass:"flex-column align-items-start"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"card mb-1"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-auto"},[_c('router-link',{staticClass:"avatar avatar-lg",attrs:{"to":{
                            name: 'door',
                            params: {
                                locationId: _vm.device.Device.LocationId,
                                doorId: _vm.device.Device.Id,
                                companyId: _vm.device.Device.CompanyId,
                            },
                        }}},[_c('img',{staticClass:"avatar-img rounded",attrs:{"src":_vm.device.Device.SmallImageUrl &&
                                _vm.device.Device.SmallImageUrl !=
                                    'https://zesec-prod.s3.eu-central-1.amazonaws.com//_150x150'
                                    ? _vm.device.Device.SmallImageUrl
                                    : '/images/zesec-placeholder.png',"alt":"..."}})])],1),_c('div',{staticClass:"col ml-n2"},[_c('h4',{staticClass:"mb-1"},[_c('router-link',{attrs:{"to":{
                                name: 'door',
                                params: {
                                    locationId: _vm.device.Device.LocationId,
                                    doorId: _vm.device.Device.Id,
                                    companyId: _vm.device.Device.CompanyId,
                                },
                            }}},[_vm._v(_vm._s(_vm.device.Device.Name))])],1),_c('p',{staticClass:"card-text small text-muted mb-1"},[_vm._v(" "+_vm._s(_vm.getDeviceProvider(_vm.device.Device.ProviderType) .key)+" ")])]),_c('div',{staticClass:"col-auto"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t("common.count_keys", { count: _vm.device.Keys ? _vm.device.Keys.length : _vm.device.KeysCount, }))+" ")]),_c('br'),_c('small',[_vm._v(" "+_vm._s(_vm.$t("common.count_users", { count: _vm.device.UsersCount, }))+" ")])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }