<template>
    <b-list-group-item class="flex-column align-items-start">
        <div class="row align-items-center">
            <div class="col-auto">
                <!-- Avatar -->
                <a href="#!" class="avatar avatar-lg">
                    <img :src="image" class="avatar-img rounded" />
                </a>
            </div>
            <div class="col ml-n2">
                <!-- Title -->
                <h4 class="mb-1 name">
                    <router-link :to="linkTo">{{ propertyName }}</router-link>
                </h4>

                <!-- Time -->
                <p class="card-text small text-muted">
                    {{ name }}
                </p>
            </div>

            <!-- v-if="userRole != 'Installer'" -->
            <div class="col-auto" v-if="settingIcon">
                <!-- Dropdown --
               !--  v-if="$can('cancel', getRoleObject(name))" -->
                <b-dropdown
                    variant="outline"
                    toggle-class="dropdown-ellipses dropdown-toggle"
                    no-caret
                    right
                    toggle-tag="a"
                >
                    <template v-slot:button-content>
                        <img :src="'/images/settings_grey_32x32dp.png'" style="width: 19px; height: 19px;"/>
                    </template>
                    <b-dropdown-item
                        class="text-left text-truncate"
                        @click="deleteUser(getLocationMember)"
                        >{{ $t("buttons.remove_role") }}</b-dropdown-item
                    >
                    <!-- v-if="employee.User.Type != 'Light'" -->
                    <b-dropdown-item
                        v-if="changeRoleFlag"
                        class="text-left text-truncate"
                        @click="changeRole(getLocationMember)"
                        >{{ $t("staff.change_role") }}</b-dropdown-item
                    >
                </b-dropdown>
            </div>
        </div>
        <!-- / .row -->
    </b-list-group-item>
</template>
<script>
import { mapGetters } from "vuex";
import {
    ROLE_COMPANY_OWNER,
    ROLE_COMPANY_MANAGER,
    ROLE_LOCATION_MANAGER,
    ROLE_DEVICE_INSTALLER,
    ROLE_KEY_MANAGER,
} from "@/acl/roles";
import {
    M_COMPANY_USER_DELETE,
    M_LOCATION_USER_DELETE,
    M_COMPANY_CHANGE_ROLE_FORM,
} from "@/components/modals/types";
import {
    MEMBER_GET,
} from "@/store/types/members";
import modalMixin from "@/mixins/modalMixin";

export default {
    name: "user-role-item-setting",
    props: ["role", "employee", "company"],
    mixins: [modalMixin],
    data() {
        return {
            userRole: "",
            propertyName: "",
            name: "",
            image: "",
            settingIcon: false,
            linkTo: null,
            locationId: null,
            userId: null,
            locationList: [],
            changeRoleFlag: false,
        };
    },
    computed: {
        ...mapGetters([
            "getLocationMember",
            "getUserRoles",
            "getUser"
        ]),
    },
    created() {
        this.user = this.$store.dispatch(MEMBER_GET, {
            locationId: this.$route.params.locationId,
            userId: this.$route.params.userId,
        });
        this.getLoggedInUserRoles();
        switch (this.role.Name) {
            case ROLE_COMPANY_OWNER:
                this.image =
                    this.role.Company.SmallImageUrl ||
                    "/images/zesec-placeholder.png";
                this.propertyName = this.role.Company.Name;
                this.name = this.$t("permissions.role_owner");
                this.linkTo = {
                    name: "company",
                    params: {
                        companyId: this.role.Company.Id,
                    },
                };
                break;
            case ROLE_COMPANY_MANAGER:
                this.image =
                    this.role.Company.SmallImageUrl ||
                    "/images/zesec-placeholder.png";
                this.propertyName = this.role.Company.Name;
                this.name = this.$t("permissions.role_cm");
                this.linkTo = {
                    name: "company",
                    params: {
                        companyId: this.role.Company.Id,
                    },
                };
                break;
            case ROLE_LOCATION_MANAGER:
                this.image =
                    this.role.Location.SmallImageUrl ||
                    "/images/zesec-placeholder.png";
                this.propertyName = this.role.Location.Name;
                this.name = this.$t("permissions.role_lm");
                this.linkTo = {
                    name: "location",
                    params: {
                        locationId: this.role.Location.Id,
                        companyId: this.role.Location.CompanyId,
                    },
                };
                break;
            case ROLE_DEVICE_INSTALLER:
                this.image =
                    this.role.Location.SmallImageUrl ||
                    "/images/zesec-placeholder.png";
                this.propertyName = this.role.Location.Name;
                this.name = this.$t("permissions.role_installer");
                this.linkTo = {
                    name: "location",
                    params: {
                        locationId: this.role.Location.Id,
                        companyId: this.role.Location.CompanyId,
                    },
                };
                break;
            case ROLE_KEY_MANAGER:
                this.image =
                    this.role.Location.SmallImageUrl ||
                    "/images/zesec-placeholder.png";
                this.propertyName = this.role.Location.Name;
                this.name = this.$t("permissions.role_km");
                this.linkTo = {
                    name: "location",
                    params: {
                        locationId: this.role.Location.Id,
                        companyId: this.role.Location.CompanyId,
                    },
                };
                break;
        }
    },
    methods: {
        /* getLoggedInUserRoles() {
            * this.getUserRoles.filter((item) => item.LocationId == this.$route.params.locationId)
            .map((item) => {
                this.userRole = item.Name;
            }); *
            this.userId = this.getUser.Id
            // this.getUserRoles.filter((item) => item.LocationId == this.$route.params.locationId)
            this.getUserRoles.filter((item) => (item.CompanyId == this.$route.params.companyId) && (item.User.Id == this.userId))
            .map((item) => {
                if(item.Name == "CompanyOwner" || item.Name == "CompanyManager" || item.Name == "LocationManager" || item.Name == "KeyManager"){
                    return this.userRole = item.Name;
                }else{
                    return
                }
            });
            if((this.userRole == "CompanyOwner") && (this.role.Name == "CompanyManager" || this.role.Name == "LocationManager" || this.role.Name == "KeyManager" || this.role.Name == "Installer")){
                this.settingIcon = true;
            }else if((this.userRole == "CompanyManager") && (this.role.Name == "CompanyManager" || this.role.Name == "LocationManager" || this.role.Name == "KeyManager" || this.role.Name == "Installer")){
                this.settingIcon = true;
            }else if(this.userRole == "LocationManager" && (this.role.Name == "KeyManager" || this.role.Name == "Installer")){
                this.settingIcon = true;
            }else if(this.userRole == "KeyManager"){
                // && this.role.Name == "Installer"
                this.settingIcon = true;
            }else{
                this.settingIcon = false;
            }
        }, */
        getLoggedInUserRoles() {
           this.userId = this.getUser.Id
           this.getUserRoles.filter((item) => (item.CompanyId == this.$route.params.companyId) && (item.User.Id == this.userId))
            .map((item) => {
                if(item.Name == "CompanyOwner" || item.Name == "CompanyManager" || item.Name == "LocationManager" || item.Name == "KeyManager"){
                    if(item.Name == "LocationManager" || item.Name == "KeyManager"){
                        this.locationList.push(item.LocationId)
                    }
                    return this.userRole = item.Name;
                }else{
                    return
                }
            });
            // console.log("role:"+this.userRole)
            if((this.userRole == "CompanyOwner") && (this.role.Name == "CompanyManager" || this.role.Name == "LocationManager" || this.role.Name == "KeyManager" || this.role.Name == "Installer")){
                this.changeRoleFlag = true;
                return this.settingIcon = true;
            }else if((this.userRole == "CompanyManager") && (this.role.Name == "CompanyManager" || this.role.Name == "LocationManager" || this.role.Name == "KeyManager" || this.role.Name == "Installer") && (this.userId != this.$route.params.userId)){
                this.changeRoleFlag = true;
                return this.settingIcon = true;
            }else{
                this.settingIcon = false;
            }
            // console.log("len:"+this.locationList.length)
            for(let i=0; i<this.locationList.length; i++){
                this.locationId = this.locationList[i]

                if((this.userRole == "LocationManager") && (this.role.Name == "CompanyOwner" || this.role.Name == "CompanyManager")){
                    return this.settingIcon = false;
                }else if((this.userRole == "LocationManager") && (this.role.Name == "KeyManager" || this.role.Name == "Installer") && (this.role.Location != null)){
                    if((this.role.Location.Id != null) && (this.role.Location.Id == this.locationList[i])){
                        this.changeRoleFlag = true;
                        return this.settingIcon = true;
                    }else{
                        this.settingIcon = false;
                    }
                }else if((this.userRole == "KeyManager")  && (this.role.Name == "CompanyOwner" || this.role.Name == "CompanyManager" || this.role.Name == "LocationManager")){
                    this.settingIcon = false;
                }else if((this.userRole == "KeyManager") && (this.role.Name == "Installer") && (this.role.Location != null)){
                    if((this.role.Location.Id != null) && (this.role.Location.Id == this.locationList[i])){
                        return this.settingIcon = true;
                    }else{
                        this.settingIcon = false;
                    }
                    this.changeRoleFlag = false;
                }else{
                    this.settingIcon = false;
                }
            }
        },
        deleteUser(user) {
            if (this.name === this.$t("permissions.role_cm")) {
                this.showModal({ user: this.role }, M_COMPANY_USER_DELETE);
            } else {
                this.showModal({ user: this.role }, M_LOCATION_USER_DELETE);
            }
        },
        changeRole(user) {
            this.showModal({ user: this.getLocationMember }, M_COMPANY_CHANGE_ROLE_FORM);
        },
        getRoleObject(roleName) {
            // return definedRoles[roleName];
            return this.name;
        },
    },
    /* components: {
    "zs-company-rerole-modal": ChangeCompanyEmployeeRole,
    "zs-delete-company-user-modal": DeleteUserModal,
    "zs-delete-location-user-modal": DeleteLocationUserModal,
  }, */
};
</script>
<style scoped>
.btn:focus{
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
}
</style>
