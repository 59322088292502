<template>
    <b-list-group-item class="flex-column align-items-start" v-if="!isMobile">
        <div class="d-flex w-100 justify-content-between row">
            <b-col lg="6" md="6" sm="12">
                <b-row>
                    <b-col cols="3" class="px-0 m-auto text-center">
                        <router-link
                                :to="{
                                    name: 'door',
                                    params: {
                                        locationId: device.Device.LocationId,
                                        doorId: device.Device.Id,
                                        companyId: device.Device.CompanyId,
                                    },
                                }"
                        >
                        <b-avatar
                            rounded="lg"
                            size="2rem"
                            variant="light"
                            class="mr-2 mw-48"
                            :src="
                                device.Device.SmallImageUrl &&
                                device.Device.SmallImageUrl !=
                                    'https://zesec-prod.s3.eu-central-1.amazonaws.com//_150x150'
                                    ? device.Device.SmallImageUrl
                                    : '/images/zesec-placeholder.png'
                            "
                        ></b-avatar></router-link>
                    </b-col>
                    <b-col cols="9" class="pl-0 my-auto">
                        <h5 class="mb-1 text-truncate">
                            <router-link
                                :to="{
                                    name: 'door',
                                    params: {
                                        locationId: device.Device.LocationId,
                                        doorId: device.Device.Id,
                                        companyId: device.Device.CompanyId,
                                    },
                                }"
                                >{{ device.Device.Name }}</router-link
                            >
                        </h5>
                    </b-col>
                </b-row>
            </b-col>
            <b-col lg="2" md="2" sm="4">
                <small>
                    {{
                        $t("common.count_keys", {
                            count: device.KeysCount,
                        })
                    }}
                </small>
            </b-col>
            <b-col lg="2" md="2" sm="4">
                <small>
                    {{
                        $t("common.count_users", {
                            count: device.UsersCount,
                        })
                    }}
                </small>
            </b-col>
            <b-col lg="2" md="2" sm="4">
                <b-badge variant="light">
                    {{ getDeviceProvider(device.Device.ProviderType).key }}
                </b-badge>
            </b-col>
        </div>
    </b-list-group-item>
    <b-list-group-item class="flex-column align-items-start" v-else>        
     <b-col cols="12">
        <div class="card mb-1">
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-auto">
                        <!-- Avatar -->
                        <router-link
                            class="avatar avatar-lg"
                            :to="{
                                name: 'door',
                                params: {
                                    locationId: device.Device.LocationId,
                                    doorId: device.Device.Id,
                                    companyId: device.Device.CompanyId,
                                },
                            }"
                        >
                            <img
                                :src="
                                    device.Device.SmallImageUrl &&
                                    device.Device.SmallImageUrl !=
                                        'https://zesec-prod.s3.eu-central-1.amazonaws.com//_150x150'
                                        ? device.Device.SmallImageUrl
                                        : '/images/zesec-placeholder.png'
                                "
                                alt="..."
                                class="avatar-img rounded"
                            />
                        </router-link>
                    </div>
                    <div class="col ml-n2">
                        <!-- Title -->
                        <h4 class="mb-1">
                            <router-link
                                :to="{
                                    name: 'door',
                                    params: {
                                        locationId: device.Device.LocationId,
                                        doorId: device.Device.Id,
                                        companyId: device.Device.CompanyId,
                                    },
                                }"
                                >{{ device.Device.Name }}</router-link
                            >
                        </h4>

                        <!-- Text -->
                        <p class="card-text small text-muted mb-1">
                            {{
                                getDeviceProvider(device.Device.ProviderType)
                                    .key
                            }}
                        </p>

                    </div>
                    <div class="col-auto">
                        <small>
                            {{
                                $t("common.count_keys", {
                                    count: device.Keys
                                        ? device.Keys.length
                                        : device.KeysCount,
                                })
                            }}
                        </small>
                        <br>
                        <small>
                            {{
                                $t("common.count_users", {
                                    count: device.UsersCount,
                                })
                            }}
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </b-col>
    </b-list-group-item>
</template>
<script>
import { getDeviceProvider } from "@/handlers/enums";

export default {
    name: "location-door-list-item",
    data() {       
        return {
            isMobile: true,   
            width: window.innerWidth,
            height: window.innerHeight         
        };
    },
    props: ["device"],
    created() {
        window.addEventListener("resize", this.onResize);
        window.addEventListener("onload", this.onLoad);
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
        window.removeEventListener("onload", this.onLoad);
        this.isMobile = true;
    },    
    methods: {
        getDeviceProvider(id) {
            return getDeviceProvider(id);
        },  
        onResize(e) {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            if(this.width < 1200){
                this.isMobile = true
            }else{
                this.isMobile = false
            }            
        },
        onLoad(evt){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.isMobile = true
            } else if(typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1){
                this.isMobile = true
            } else {
                this.isMobile = false
            }            
        },    
    },    
};
</script>
