import activityController from "./activity";
import adminController from "./admin";

import {
    ACTIVITY_LOG_LOCATION,
    ACTIVITY_LOG_KEY,
    ACTIVITY_LOG_KEY_PG,
    ACTIVITY_LOG_DEVICE,
    ACTIVITY_LOG_DEVICE_PG,
    ACTIVITY_LOG_COMPANY,
    ACTIVITY_LOG_ADMIN,
    ACTIVITY_LOG_LOCATION_PG,
    ACTIVITY_LOG_ADMIN_PG, ACTIVITY_LOG_EXPORT

} from "@/handlers/const";

import{
    ADMIN_ACTIVITY_GET
} from "@/store/types/admin";

export default {
    getLogOptions(options) {
        let logOptions = {
            OrderMode: 1,
            OrderBy: "date",
            FilterType: options.FilterType ?? '',
            FilterValue: options.FilterValue ?? 0,
            ItemsPerPage: options.limit ?? 20,
            Page: options.page ?? 0,
            EventTypes: options.EventTypes ?? [],
            StartDate: options.StartDate,
            EndDate: options.EndDate,
            IgnorePagination: options.IgnorePagination ?? true,
            KeyId: options.KeyId ?? '',
            DeviceId: options.DeviceId ?? '',
            UserId: options.UserId ?? '',
            LocationId: options.locationId ?? '',
        };

        if (options.FileName != null)
            logOptions.FileName = options.FileName

        return logOptions
    },

    //Group requests
    getActivityLog(options, type) {
        let logOptions = this.getLogOptions(options)

        switch (type) {
            case ACTIVITY_LOG_LOCATION:
                return activityController.getLocationActivity(
                    options.locationId,
                    logOptions
                );

            case ACTIVITY_LOG_LOCATION_PG:
                return activityController.getLocationActivityPg(
                    options.locationId,
                    logOptions
                );

            case ACTIVITY_LOG_KEY:
                return activityController.getKeyActivity(
                  options.keyId,
                  logOptions,
                );

            case ACTIVITY_LOG_KEY_PG:
                return activityController.getKeyActivityPg(
                  options.keyId,
                  logOptions,
                );

            case ACTIVITY_LOG_DEVICE:
                return activityController.getDeviceActivity(
                  options.deviceId,
                  logOptions,
                );

            case ACTIVITY_LOG_DEVICE_PG:
                return activityController.getDeviceActivityPg(
                  options.deviceId,
                  logOptions,
                );

            case ACTIVITY_LOG_COMPANY:
                return activityController.getCompanyActivity(
                    options.companyId,
                    logOptions,
                );

            case ACTIVITY_LOG_ADMIN:
                return adminController.getAllAdminActivities(
                    logOptions,
                );

            case ACTIVITY_LOG_ADMIN_PG:
                return adminController.getAllAdminActivitiesPg(
                    logOptions,
                );
        }
    },

    getExportActivityLog(options, type) {
        let logOptions = this.getLogOptions(options)

        switch (type) {
            case ACTIVITY_LOG_LOCATION:
                return activityController.getExportLocationActivity(
                    options.locationId,
                    logOptions
                );
            case ACTIVITY_LOG_ADMIN:
                return adminController.getExportAllAdminActivities(
                  logOptions
                );
            case ACTIVITY_LOG_EXPORT:
                return activityController.getExportActivityStart(
                  logOptions
                );

            /*case ACTIVITY_LOG_KEY:
                return activityController.getKeyActivity(
                    options.keyId,
                    logOptions,
                );

            case ACTIVITY_LOG_DEVICE:
                return activityController.getDeviceActivity(
                    options.deviceId,
                    logOptions,
                );

            case ACTIVITY_LOG_COMPANY:
                return activityController.getCompanyActivity(
                    options.companyId,
                    logOptions,
                );

            case ACTIVITY_LOG_ADMIN:
                return adminController.getAllAdminActivities(
                    logOptions,
                );*/
        }
    },
};
