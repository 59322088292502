import AggregatedController from "@/api/aggregated";

export default {
    data() {
        return {
            activity: [],
            currPage:  0,
            pg: {},
            totalRows: 0,
            loading: true,
            activityNext: [],
            nextPage:  0,
            loadingNext: false,
            more: false,
            options: {},
            type: null,
        };
    },

    methods: {
        loadActivity(options, type) {
            this.loading = true;
            this.options = options;
            this.type = type;
            this.currPage = options?.page ?? 0;
            AggregatedController.getActivityLog(options, type).then(
                (response) => {
                    this.activity = response.data;
                    this.activity.sort((i1, i2) => {
                        return i1.Time < i2.Time
                            ? 1
                            : -1;
                    });
                    this.loading = false;
                }
            );
            //next page preload
            if (("page" in options) && (options.IgnorePagination != true)) {
                this.more = true;
                this.loadNext();
            }
        },
        loadActivityPg(options, type) {
            this.totalRows = 0;
            AggregatedController.getActivityLog(options, type).then(
                (response) => {
                    response.data.text().then(
                        (txt) => {
                            this.pg = JSON.parse(txt);
                            this.totalRows = this.pg?.LinesCount ?? 0;
                        }
                    )
                }
            );
        },
        loadFullActivity(options, type) {
            this.options = options;
            this.type = type;
            AggregatedController.getActivityLog(options, type).then(
                (response) => {
                    this.activity = response.data;
                    this.loading = false;
                }
            );
        },
        loadNext() {
            this.nextPage = -100;
            if (this.more && !this.loadingNext) {
                this.activity = this.activity.concat(this.activityNext);
                this.options.page++;
                this.loadingNext = true;

                AggregatedController.getActivityLog(
                    this.options,
                    this.type
                ).then((response) => {
                    this.activityNext = response.data;
                    if (response.data.length == 0) {
                        this.more = false;
                    } else {
                        this.more = true;
                        this.nextPage = this.options.page;
                    }
                    this.loadingNext = false;
                });
            }
        },
    },
};
